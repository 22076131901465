import React, { useEffect, useMemo, useReducer, useState } from "react";
import { useForm } from "react-hook-form";

import { withAppContext } from "../../components/withAppContext";
import { FormattedMessage, injectIntl } from "react-intl";

import { v4 as uuid } from "uuid";
import { z } from "zod";

import { List } from "../../components/List/List";
import { PortalSettings } from "../../components/PortalSettings/portal-settings";
import { AccessibilityMenu } from "../../components/AccessibilityMenu/AccessibilityMenu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * @function SettingsPage
 * @param {{ appContext: AppContext.Context, availablePortals: API.PortalObject[], intl: { formatMessage: (p: { id: string }) => string } }} props
 */
const SettingsPage = (props) => {
    const { formatMessage } = props.intl;

    const [user, setUser] = useState(props.appContext.user);
    const [portals, setPortals] = useState(user.portals);

    useEffect(() => {
        (async () => {
            setUser(await apiFetchProfile("me"));
        })();
    }, [setUser]);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            ageXml: user.ageXml,
            apiOnTheFly: user.apiOnTheFly,
        },
    });

    /**@type {ReactExt.State<API.PortalObject[]>}*/
    const [availablePortals, setAvailablePortals] = useState();

    useEffect(() => {
        (async () => {
            setAvailablePortals(await adminApiPortalList());
        })();
    }, [setAvailablePortals]);

    const [ips, setIps] = useState(
        user.ips.map((ip) => ({ ...ip, uuid: uuid() })),
    );

    const dynamicIps = useMemo(() => ips.filter(({ dyndns }) => dyndns), [ips]);
    const staticIps = useMemo(() => ips.filter(({ dyndns }) => !dyndns), [ips]);

    /**@type {Parameters<typeof handleSubmit>[0]} */
    // @ts-ignore
    const onValid = async (data, event) => {
        await apiUpdateProfile({
            ...user,
            ...data,
            ips,
            portals,
        })
            .then((user) => {
                props.appContext.changeUserState(user);
                window.scrollTo({ top: 0, behavior: "smooth" });
                toast("Saved changes!", {
                    // @ts-ignore
                    position: toast.POSITION.TOP_CENTER,
                    // @ts-ignore
                    type: toast.TYPE.SUCCESS,
                    autoClose: 1000,
                });
            })
            .catch((e) => {
                // TODO: Show error
            });
    };

    /**@type {Parameters<typeof handleSubmit>[1]} */
    // @ts-ignore
    const onInvalid = (errors, event) => {
        // TODO: Show alert
    };

    return (
        <>
            <AccessibilityMenu i18nKey="settings" />
            <ToastContainer />
            <form
                className="form-horizontal"
                onSubmit={handleSubmit(onValid, onInvalid)}
            >
                <h4 className="form-label">
                    <FormattedMessage id={"profile.ip_addresses"} />
                </h4>

                <div
                    dangerouslySetInnerHTML={{
                        __html: `<p>${formatMessage({
                            id: "profile.ip_addresses_info",
                        })}</p>`,
                    }}
                />

                {dynamicIps.length > 0 && (
                    <div className="form-group">
                        <div className="col-3 col-sm-12">
                            <label className="form-label" htmlFor="age">
                                Dynamic IPs
                            </label>
                        </div>

                        <div className="col-9 col-sm-12">
                            <List
                                items={dynamicIps}
                                accessor={({ ip }) => ip}
                                addItem={false}
                                updateItem={false}
                                removeItem={(uuid) => {
                                    setIps((old) =>
                                        old.filter((ip) => ip.uuid !== uuid),
                                    );
                                }}
                            />
                        </div>
                    </div>
                )}
                <div className="form-group">
                    <div className="col-3 col-sm-12">
                        <label className="form-label" htmlFor="age">
                            Static IPs
                        </label>
                    </div>
                    <div className="col-9 col-sm-12">
                        <List
                            items={staticIps}
                            accessor={({ ip }) => ip}
                            validator={(item) => {
                                return z.string().ip().safeParse(item).error
                                    ?.issues?.[0]?.message;
                            }}
                            addItem={() => {
                                setIps((old) => [
                                    ...old,
                                    {
                                        ip: "",
                                        dyndns: false,
                                        uuid: uuid(),
                                    },
                                ]);
                            }}
                            updateItem={(uuid, value) => {
                                setIps((old) =>
                                    old.map((ip) =>
                                        ip.uuid === uuid
                                            ? { ...ip, ip: value }
                                            : ip,
                                    ),
                                );
                            }}
                            removeItem={(uuid) => {
                                setIps((old) =>
                                    old.filter((ip) => ip.uuid !== uuid),
                                );
                            }}
                        />
                    </div>
                </div>

                <h4>
                    <FormattedMessage id="profile.uses" />
                </h4>
                <div className="form-group">
                    <label className="form-checkbox">
                        <input type="checkbox" {...register("ageXml")} />
                        <i className="form-icon"></i>{" "}
                        {formatMessage({ id: "settings.use_age_xml" })}
                    </label>
                </div>
                <div className="form-group">
                    <label className="form-checkbox">
                        <input type="checkbox" {...register("apiOnTheFly")} />
                        <i className="form-icon"></i>{" "}
                        {formatMessage({ id: "settings.use_otf" })}
                    </label>
                </div>

                {availablePortals && availablePortals.length > 0 && (
                    <>
                        <h4>Online Portals</h4>
                        {availablePortals.map((portal) => {
                            const currentPortal = portals.find(
                                (p) => p.name === portal.name,
                            );

                            return (
                                <PortalSettings
                                    key={portal.name}
                                    name={portal.name}
                                    allowed={currentPortal?.active ?? false}
                                    safeSearch={
                                        currentPortal?.safeSearch ?? false
                                    }
                                    hasSafeSearch={
                                        portal.safeSearches.length > 0
                                    }
                                    age={portal.age}
                                    globalAge={12}
                                    text={portal.text}
                                    toggleAllowed={() => {
                                        setPortals((old) =>
                                            // TODO: Validate that `old` contains all the available portals
                                            old.map((p) => {
                                                return p.name === portal.name
                                                    ? {
                                                          ...p,
                                                          active: !p.active,
                                                      }
                                                    : p;
                                            }),
                                        );
                                    }}
                                    toggleSafeSearch={() => {
                                        setPortals((old) =>
                                            // TODO: Validate that `old` contains all the available portals
                                            old.map((p) => {
                                                return p.name === portal.name
                                                    ? {
                                                          ...p,
                                                          safeSearch:
                                                              !p.safeSearch,
                                                      }
                                                    : p;
                                            }),
                                        );
                                    }}
                                />
                            );
                        })}
                    </>
                )}

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                        marginTop: "1rem",
                    }}
                >
                    <button className="btn btn-primary" type="submit">
                        <i
                            className="icon icon-check"
                            style={{ marginRight: "0.4rem" }}
                        />
                        Submit
                    </button>
                    <button
                        className="btn"
                        type="button"
                        onClick={() => {
                            reset();
                            setPortals(user.portals);
                            setIps(
                                user.ips.map((ip) => ({
                                    ...ip,
                                    uuid: uuid(),
                                })),
                            );
                        }}
                    >
                        <i
                            className="icon icon-cross"
                            style={{ marginRight: "0.4rem" }}
                        />
                        Cancel
                    </button>
                </div>
            </form>
        </>
    );
};

const customProps = {
    localeKey: "dashboard",
    withAuth: true,
    title: <FormattedMessage id="profile.title" />,
};

import withLayout from "../../layout";
import { adminApiPortalList } from "../../util/adminApi";
import { apiFetchProfile, apiUpdateProfile } from "../../util/api";
import { AppContext } from "../../components/AppContext";
export default withLayout(customProps)(
    withAppContext(injectIntl(SettingsPage)),
);
